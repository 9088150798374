<template>
  <svg
    class="icon"
    width="100%"
    height="100%"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m23.64,2.52l-.05,21.08-21.08.05"
      stroke="currentColor"
      stroke-width="2"
    />
    <path d="m23.59,23.59L.71.71" stroke="currentColor" stroke-width="2" />
  </svg>
</template>
